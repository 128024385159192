/** @format */

export const videos = [
  {
    id: 0,
    url: "https://www.youtube.com/embed/e8-NP76rG9Q",
    Dis: "Nifty, Bank Nifty & Best stocks to trade for 16 Feb  ",
    tum: "https://i.ytimg.com/vi/e8-NP76rG9Q/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCEWI2xJJ9YHB7gV6BPejRZxDSHFA",
  },
  {
    id: 1,
    url: "https://www.youtube.com/embed/jVF4xfa4MvA",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 2,
    url: "https://www.youtube.com/embed/PcuYqjDMAcs",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 3,
    url: "https://www.youtube.com/embed/3liT-0L4Klg",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 14,
    url: "https://www.youtube.com/embed/JeY7EJAGc5g",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 5,
    url: "https://www.youtube.com/embed/Yw7b6Nat-T4",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 6,
    url: "https://www.youtube.com/embed/6cn2F-GPBkY",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 7,
    url: "https://www.youtube.com/embed/vfB23MTEgqw",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 8,
    url: "https://www.youtube.com/embed/r03_m87mWGE",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 9,
    url: "https://www.youtube.com/embed/w6IK-Y96E7Y",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 10,
    url: "https://www.youtube.com/embed/HoLMmI4ui4E",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 11,
    url: "https://www.youtube.com/embed/N22sCUe0jgU",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/jVF4xfa4MvA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAnqTSW_UCNUJJP4hD4-32i3rH78g",
  },
  {
    id: 12,
    url: "https://www.youtube.com/embed/SC6lXJ0HVS4",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/SC6lXJ0HVS4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD4LpgJ1-1YqDoJ59YkCb3BhWpT-Q",
  },
  {
    id: 13,
    url: "https://www.youtube.com/embed/z--np5BHRtI",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/SDaspsmMFzU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBiXHPahoWJPFP5VBCUZRc_Tctr9Q",
  },
  {
    id: 14,
    url: "https://www.youtube.com/embed/Qng3xbr05Vs",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/Qng3xbr05Vs/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBa7g6qzaCS07iqjo9jo4QstGCXoA",
  },
  {
    id: 15,
    url: "https://www.youtube.com/embed/5t_DPkcZ2XU",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/5t_DPkcZ2XU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjGTz1ShAml_mSLwDM0Q9UHwPLhg",
  },
  {
    id: 16,
    url: "https://www.youtube.com/embed/V7QpLT2jpDU",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/V7QpLT2jpDU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLALXjt6w5kEaKKP-DLfEJWWFrTUYw",
  },
  {
    id: 17,
    url: "https://www.youtube.com/embed/5umY4Mc7pzA",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/5umY4Mc7pzA/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBlUYZmA1nVplGOMLMeMvWOb1Sm-Q",
  },
  {
    id: 18,
    url: "https://www.youtube.com/embed/gsdAoxghWrU",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/gsdAoxghWrU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAu-eczyYAQhrD7qxfcDmzekz4teQ",
  },
  {
    id: 19,
    url: "https://www.youtube.com/embed/a2YAmP03RjY",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/a2YAmP03RjY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxXOMayNAQarrW1tE9C6KNmeKAJg",
  },
  {
    id: 20,
    url: "https://www.youtube.com/embed/uz8BGPudSX4",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/uz8BGPudSX4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCq2RkZLEZHSXeOJLo9sXaeUk-05g",
  },
  {
    id: 21,
    url: "https://www.youtube.com/embed/y5EY10Xr17o",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/y5EY10Xr17o/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA486ti_UHLPlpc347T2k1pkc9AWg",
  },
  {
    id: 22,
    url: "https://www.youtube.com/embed/vOHqI3uZfH4",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/vOHqI3uZfH4/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAXglLRJvyNRMLDLcP5OgfvrIxRvQ",
  },
  {
    id: 23,
    url: "https://www.youtube.com/embed/UJPY3zyHACY",
    Dis: " amaan ",
    tum: "https://i.ytimg.com/vi/UJPY3zyHACY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBW2F5yweF_IsZ4MCxo0yZO7v5lzg",
  },
];
